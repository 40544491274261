/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const AccountIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M14.3 11.36c1.32-.78 2.2-2.22 2.2-3.86C16.5 5.02 14.48 3 12 3S7.5 5.02 7.5 7.5c0 1.64.88 3.08 2.2 3.86-3.02.97-5.2 3.8-5.2 7.14v1c0 .28.22.5.5.5s.5-.22.5-.5v-1c0-3.58 2.92-6.5 6.5-6.5s6.5 2.92 6.5 6.5v1c0 .28.22.5.5.5s.5-.22.5-.5v-1c0-3.34-2.18-6.17-5.2-7.14zM8.5 7.5C8.5 5.57 10.07 4 12 4s3.5 1.57 3.5 3.5S13.93 11 12 11 8.5 9.43 8.5 7.5z" />
	</svg>
))
AccountIconSvg.displayName = 'AccountIconSvg'

const AccountIcon = forwardRef((props, ref) => <Icon component={AccountIconSvg} ref={ref} {...props} />)
AccountIcon.displayName = 'AccountIcon'

AccountIcon.defaultProps = {
	...Icon.defaultProps,
}
AccountIcon.propTypes = {
	...Icon.propTypes,
}

export default AccountIcon
export { AccountIconSvg }
